@font-face {
  font-family: "Avenir";
  src: url("../public/fonts/Avenir.ttc") format("ttc");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  overflow: hidden;
  background-color: #040404;
  height: 100vh;

  font-family: "Avenir", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

code {
  font-family: "Avenir", sans-serif;
}
/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}
